<template>
  <loginForm type="messageLogin" :pageList="pageList" />
</template>

<script>
import loginForm from '@/components/loginForm'

export default {
  name: 'MessageLogin',
  components: {
    loginForm
  },
  data () {
    return {
      pageList: [
        {
          name: 'Masuk via kata sandi',
          activate: false
        },
        {
          name: 'Masuk dengan SMS',
          activate: true
        }
      ]
    }
  }
}
</script>
